import * as React from "react"
import { StaticImage } from "gatsby-plugin-image"
import Layout from "../components/layout"
const IndexPage = () => (
  <Layout>
    <div class="overflow-x-hidden bg-gradient-to-b from-blue-800">
      <div class="px-6 py-8">
        <div class="container flex justify-between mx-auto">
          <section class="text-gray-600 body-font">
            <div class="container mx-auto">
              <h1 class="text-xl text-white text-center  p-4 justify-center text-5xl	">
                Resources
              </h1>
              <div class="flex flex-wrap -m-4">
                <div class="lg:w-1/3 w-1/2 p-4">
                  <a href="#">
                    <div class="flex relative">
                      <StaticImage
                        src="../images/placeholder.png"
                        class="absolute inset-0 w-full h-full object-cover object-center"
                        alt="Pastor"
                      />
                      <div class="px-8 py-10 relative z-10 w-full border-4 border-gray-200 bg-indigo-500 opacity-0 hover:opacity-90">
                        <h2 class="tracking-widest text-sm title-font font-medium text-white mb-1">
                          THE SUBTITLE
                        </h2>
                        <h1 class="title-font text-lg font-bold text-white hover:text-indigo-100 mb-3">
                          Shooting Stars
                        </h1>
                        <p class="leading-relaxed text-white">
                          Photo booth fam kinfolk cold-pressed sriracha leggings
                          jianbing microdosing tousled waistcoat.
                        </p>
                      </div>
                    </div>
                  </a>
                </div>

                <div class="lg:w-1/3 w-1/2 p-4">
                  <a href="#">
                    <div class="flex relative">
                      <StaticImage
                        src="../images/placeholder.png"
                        class="absolute inset-0 w-full h-full object-cover object-center"
                        alt="Pastor"
                      />
                      <div class="px-8 py-10 relative z-10 w-full border-4 border-gray-200 bg-indigo-500 opacity-0 hover:opacity-90">
                        <h2 class="tracking-widest text-sm title-font font-medium text-white mb-1">
                          THE SUBTITLE
                        </h2>
                        <h1 class="title-font text-lg font-bold text-white hover:text-indigo-100 mb-3">
                          Shooting Stars
                        </h1>
                        <p class="leading-relaxed text-white">
                          Photo booth fam kinfolk cold-pressed sriracha leggings
                          jianbing microdosing tousled waistcoat.
                        </p>
                      </div>
                    </div>
                  </a>
                </div>
              </div>
            </div>
          </section>
        </div>
      </div>
    </div>
  </Layout>
)

export default IndexPage
